import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'ECG',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x3B041B82D68a9dC8140291174Fc7C710b3e824fE',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"

    },
    tokenSymbol: 'ECG',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0xbBDA0d3d5593a4561930Ef2977a8EF62Ae984DD0',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: "ECG",
    // token2:"MATIC"
  },
  // TODO: Need to be changed
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'TUSD',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x44B0E3fE61950e7503B82Dc60C2301039136cb29',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: "TUSD",
    isTokenOnly: true,
    // token2:"MATIC"
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'DAI',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: "DAI",
    isTokenOnly:true,
    // token2:"MATIC"
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'ECG-MATIC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x3B041B82D68a9dC8140291174Fc7C710b3e824fE',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x3B041B82D68a9dC8140291174Fc7C710b3e824fE',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: "ECG",
    token2:"MATIC"
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'ECG-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x99c530ab3A133DC35B952E3607D51DA1bF5da61D',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x99c530ab3A133DC35B952E3607D51DA1bF5da61D',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: "ECG",
    token2:"USDT"
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'ECG-DAI LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x0934339DD8f15e954eD4C7357f9E493651AFaD51',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x0934339DD8f15e954eD4C7357f9E493651AFaD51',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
    token1: "ECG",
    token2:"DAI"
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'MATIC-DAI LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: "MATIC",
    token2:"DAI"
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'MATIC-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3',
      80001: "0x66eb40d08c36adfd715b318fe88582edeef711fd"
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3',
      80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    token1: "MATIC",
    token2:"USDT"
  },
  // {
  //   pid: 4,
  //   risk: 5,
  //   lpSymbol: 'DAI-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
  //     137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "DAI",
  //   token2: "MATIC"
  // },

  // {
  //   pid: 5,
  //   risk: 3,
  //   // isTokenOnly: true,
  //   lpSymbol: 'MATIC-USDT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56',
  //     137: '0x604229c960e5CACF2aaEAc8Be68Ac07BA9dF81c3', // AUTO-BNB LP
  //     // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "MATIC",
  //   token2: "USDT"
  // },
  // {
  //   pid: 6,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56',
  //     137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // AUTO-BNB LP
  //     // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "USDT"
  // },
  // {
  //   pid: 7,
  //   risk: 3,
  //   lpSymbol: 'DAI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //     137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "DAI",
  //   isTokenOnly: true,
  //   // token2:"USDT"
  // },
  // {
  //   pid: 8,
  //   risk: 3,
  //   lpSymbol: 'ECG-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //     137: '0x59dDA2cb90418ac8a54b932aC478513A20bB09C5',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "ECG",
  //   // isTokenOnly: true,
  //   token2:"MATIC"
  // },

  // {
  //   pid: 9,
  //   risk: 3,
  //   lpSymbol: 'ECG-USDT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //     137: '0xb4BeC5A44eb56DFcB77f034BB17fC30D2e0B9AA6',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "ECG",
  //   // isTokenOnly: true,
  //   token2:"USDT"
  // },

  // {
  //   pid: 10,
  //   risk: 3,
  //   lpSymbol: 'ECG-DAI LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //     137: '0x978cf56233C19ba214DC5c020B64f2175a5A8B93',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "DAI",
  //   token2:"ECG"
  //   // isTokenOnly: true,
  //   // token2:"USDT"
  // },
  // {
  //   pid: 11,
  //   risk: 3,
  //   lpSymbol: 'ECG-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //     137: '0xb4BeC5A44eb56DFcB77f034BB17fC30D2e0B9AA6',

  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //     137: '0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71',

  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   token1: "DAI",
  //   // isTokenOnly: true,
  //   // token2:"USDT"
  // },


  // {
  //   pid: 7,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'TUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'ECG',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   token1:"TUSD"
  // },
]

export default farms
