import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import multicall from 'utils/multicall'
import { getRefferalAddress } from 'utils/addressHelpers'
import refferalAbi from 'config/abi/refferal.json'

import useRefresh from './useRefresh'

const useRefferal = () => {
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()
  const [result, setResults] = useState({ refferdBy: '', totalRfferal: 0, totalReferralCommissions: 0 })

  useEffect(() => {
    const fetchBalances = async () => {
      const calls = [
        {
          address: getRefferalAddress(),
          name: 'getReferrer',
          params: [account],
        },
        {
          address: getRefferalAddress(),
          name: 'referralsCount',
          params: [account],
        },
        {
          address: getRefferalAddress(),
          name: 'totalReferralCommissions',
          params: [account],
        },
      ]

      const rawResults = await multicall(refferalAbi, calls)
      console.log(rawResults, 'rawresults')
      const data = result
      if (rawResults[0][0] === '0x0000000000000000000000000000000000000000') {
        data.refferdBy = '-'
      } else {
        data.refferdBy = rawResults[0][0]
      }

      data.totalRfferal = new BigNumber(rawResults[1][0]._hex).toNumber()
      data.totalReferralCommissions = new BigNumber(rawResults[2][0]._hex).toNumber()
      //  {
      //     refferdBy: rawResults[0][0],
      //     totalRfferal: new BigNumber(rawResults[1][0]._hex),
      //     totalReferralCommissions: new BigNumber(rawResults[2][0]._hex)
      // }
      setResults(data)
    }

    if (account) {
      fetchBalances()
    }
  }, [account, fastRefresh, result])

  return result
}

export default useRefferal
