import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Card, CardBody, Heading, Skeleton, Text } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import { useGetStats } from 'hooks/api'
import useRefferal from 'hooks/useRefferal'
import CardValue from './CardValue'

const StyledTotalValueLockedCard = styled(Card)`
  align-items: center;
  display: flex;
  flex: 1;
  background-repeat: no-repeat;
  background-position: 95% center;
  //   background-size:120px;
  width: 100%;
`

const RefferalCard = () => {
  const TranslateString = useI18n()
  // const data = useGetStats()
  const totalRfferal = useRefferal()
  // const tvl = totalValue.toFixed(2);
  return (
    <StyledTotalValueLockedCard>
      <CardBody>
        {console.log(totalRfferal, 'totalValue')}
        <Heading size="lg" style={{ fontSize: '2.5em' }} mb="24px">
          Referral Details
        </Heading>
        {totalRfferal && (
          <>
            <Text style={{ fontSize: '1.5em' }} color="text">
              Total Referral Earnings
            </Text>
            <CardValue fontSize="4em" value={totalRfferal.totalReferralCommissions} />
          </>
        )}
        {totalRfferal && (
          <>
            <Text style={{ fontSize: '1.5em' }} color="text">
              Total Referral
            </Text>
            <CardValue fontSize="2.5em" value={totalRfferal.totalRfferal} decimals={0} />
          </>
        )}
        {totalRfferal && (
          <>
            <Text style={{ fontSize: '1.5em' }} color="text">
              Referred By
            </Text>

            <Text color="textSubtle">{totalRfferal.refferdBy} </Text>
          </>
        )}
      </CardBody>
    </StyledTotalValueLockedCard>
  )
}

export default RefferalCard
