export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    97: '',
    137: '0xbBDA0d3d5593a4561930Ef2977a8EF62Ae984DD0',
    80001: "0xa2b09fC8A34961623Aae02a890d383D91B979555"
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    97: '',
    137: "0x6031146748a8176587a4bfBd2eE79d787c993333",
    80001: "0xd490648aE56f5e64560b922Ac2e280A50b1564a5"
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    80001: "0x9c3c9283d3e44854697cd22d3faa240cfb032889"
  },
  lottery: {
    56: '',
    97: '',
    137: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
    137: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
    80001: "0xBBEa47c3746912809c554b38b426f43ABA411eec"
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    80001: "0xe583769738b6dd4e7caf8451050d1948be717679"
  },
  dai: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    80001: "0xe583769738b6dd4e7caf8451050d1948be717679"
  },
  refferal: {
    56: '0x4f8012DE3dCF0ba7089cEe58b8e9124DA70D0E81',
    97: '0x3d51D0422f4F1847A31ef5191B63733D1115F5Af',
    137: '0x0Cd2Ed042ff0933Edd7a804455e8731eFA3e2CF3',
    80001: "0x06Bf0B6B714e706225fF18795a58B02aBb4DE227"
  },

}
