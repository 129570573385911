import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import axios from "axios"



const useGetPriceData = () => {
  const [data, setData] = useState<BigNumber>(new BigNumber(0))
  const API_URL = "https://api.dex.guru/v1/tokens/0xbBDA0d3d5593a4561930Ef2977a8EF62Ae984DD0-polygon"


  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(API_URL);
      // axios.get(API_URL).then((re)=>{
      //   setCakePriceBusd(new BigNumber(re.data.data.price))
      // });
      console.log(resp.data.priceUSD,"price");

      setData(new BigNumber(resp.data.priceUSD))
    }

    fetchData()
  }, [])

  return data
}

export default useGetPriceData