import React from 'react'
import styled from 'styled-components'
import { Heading, Text, BaseLayout, Image, Button, Flex, Card, CardBody } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import Page from 'components/layout/Page'
import CakeStats from 'views/Refferal/components/CakeStats'

const Cards = styled(BaseLayout)`
align-items: stretch;
justify-content: stretch;
margin-bottom: 48px;

& > div {
  grid-column: span 6;
  width: 100%;
}

${({ theme }) => theme.mediaQueries.sm} {
  & > div {
    grid-column: span 8;
  }
}

${({ theme }) => theme.mediaQueries.lg} {
  & > div {
    grid-column: span 6;
  }
}
`


const Aboutexchange: React.FC = () => {


    const TranslateString = useI18n()

    return (
        <Page>
            <Cards>
                <div>
                    <div>
                        <p style={{ color: 'white', fontSize: '2.5rem' }}>
                            The First DEX on multichain with a referral system
                        </p>
                        <Text fontSize="1.5rem" style={{ marginTop: '2rem', color: '#0F52BA' }}>
                            Enjoy profitable yield farming and exchanges with the lowest fees in DeFi space
                        </Text>
                    </div>

                    <Flex style={{ marginTop: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <img src="/images/bnb.png" alt="bsc" style={{ width: "50px" }} />
                            <Button as="a" href="https://bscswap.echangy.com/#/swap">
                                BUY ECG
                            </Button>
                        </div>
                        <div style={{ display: "flex" }}>
                            <img src="/images/polygon.png" alt="bsc" style={{ width: "50px" }} />

                            <Button as="a" href="https://polygonswap.echangy.com/#/swap">
                                BUY ECG
                            </Button>
                        </div>
                    </Flex>
                </div>
                <div>
                    <img src="/images/robot.png" alt="robot" style={{ width: "50%", margin: "auto", display: "block" }} />
                </div>
            </Cards>
            <Cards>
                <CakeStats />


                {/* <div>
                    <Flex>
                        <Heading>
                            Token Name :
                        </Heading>
                        <Heading>
                            ECHANGY Token
                        </Heading>
                    </Flex>
                    <br />
                    <Flex>
                        <Heading>
                            Token Symbol :
                        </Heading>
                        <Heading>
                            ECG
                        </Heading>
                    </Flex>
                    <br />
                    <Flex>
                        <Heading>
                            BSC Contract
                        </Heading>

                    </Flex>
                    <Heading>
                        0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71
                    </Heading>
                    <br />
                    <Flex>
                        <Heading>
                            Polygon Contract
                        </Heading>

                    </Flex>
                    <Heading>
                        0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71
                    </Heading>
                    <br />
                </div> */}
                {/* <div>
                    <img src="/images/graph.png" alt="graph" style={{ width: "120%" }} />
                </div> */}
            </Cards>
            <div>
                <Text fontSize="3rem">
                    ECG Token Opportunities
                </Text>
                <Cards>
                    <Card>
                        <img src="/images/c1.png" alt="card1" style={{ width: "100%" }} />
                        <CardBody>
                            <Text fontSize="2rem">
                                Trading
                            </Text>
                            <Text color="#0096FF">
                                Easily trade ECG Token on the platform with the lowest transaction
                                fees in the DeFi space-Echangy.
                            </Text>

                        </CardBody>
                    </Card>
                    <Card>
                        <img src="/images/c2.png" alt="card1" style={{ width: "100%" }} />
                        <CardBody>
                            <Text fontSize="2rem">
                                Echangy Launchpools
                            </Text>
                            <Text color="#0096FF">
                                Stake your ECG tokens in Echangy Launchpools
                                and earn other token for free
                            </Text>

                        </CardBody>
                    </Card>
                    <Card>
                        <img src="/images/c3.png" alt="card1" style={{ width: "100%" }} />
                        <CardBody>
                            <Text fontSize="2rem">
                                Echangy Farms
                            </Text>
                            <Text color="#0096FF">
                                Stake your Liquidity provider token in
                                Echangy Farms pairs and recieve ECG
                                token in return
                            </Text>

                        </CardBody>
                    </Card>
                    <Card>
                        <img src="/images/c4.png" alt="card1" style={{ width: "100%" }} />
                        <CardBody>
                            <Text fontSize="2rem">
                                Referral Commission
                            </Text>
                            <Text color="#0096FF">
                                Recieve 5% from your friends` earning
                                in farms & Launchpools
                                in the form of ECG token
                            </Text>

                        </CardBody>
                    </Card>
                    <Card>
                        <img src="/images/c5.png" alt="card1" style={{ width: "100%" }} />
                        <CardBody>
                            <Text fontSize="2rem">
                                Liquidity Provider Fee
                            </Text>
                            <Text color="#0096FF">
                                Add ECG tokens along with other tokens
                                to the liquidity pools on echangy
                            </Text>

                        </CardBody>
                    </Card>
                </Cards>
            </div>
            <hr />

            <Cards>
                <div>
                    <img src="/images/egg/9.png" alt="logo" style={{ width: "50%", display: "block", margin: "auto" }} />
                </div>
                <div>
                    <Text fontSize="2rem">
                        Want to integrate your ECG token into your platform/service
                    </Text>
                    <Text color="#0096FF">
                        get in touch with us via cooperation@echangy.com and the team
                        will be carefully analyze the possibility of connecting your
                        project. The best products based on your initial review will be added
                        to the list of ECG Token Opportunities and will get the marketing boot
                        in the form of promotion across our social media profiles accounting
                        for more then 430,000 users.
                    </Text>
                </div>
            </Cards>
            <hr />
            <Text fontSize="2rem" style={{ textAlign: "center" }}>
                Become a holder of the token that supports the Echangy ecosystem.
            </Text>
            {/* <Flex style={{ marginTop: "20px" }}>
                <div style={{ display: "flex" }}>
                    <img src="/images/bnb.png" alt="bsc" style={{ width: "50px" }} />
                    <Button as="a" href="https://swap.echangy.com/#/swap?outputCurrency=0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71">
                        BUY ECG
                    </Button>
                </div>
                <div style={{ display: "flex" }}>
                    <img src="/images/polygon.png" alt="bsc" style={{ width: "50px" }} />

                    <Button as="a" href="https://polygonswap.echangy.com/#/swap?outputCurrency=0xa265aD7157BF93a32202d7B4b920dA3BAcB7aE71">
                        BUY ECG
                    </Button>
                </div>
            </Flex> */}
        </Page >
    )
}

export default Aboutexchange
