import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, Heading, Skeleton, Text, Button } from '@pancakeswap-libs/uikit'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useWallet } from '@binance-chain/bsc-use-wallet'

const StyledTotalValueLockedCard = styled(Card)`
  align-items: center;
  display: flex;
  flex: 1;
  background-repeat: no-repeat;
  background-position: 95% center;
  //   background-size:120px;
`

const RefferalLink = () => {
  const { account } = useWallet()

  return (
    <StyledTotalValueLockedCard>
      <CardBody>
        <Heading style={{ fontSize: '2.5em' }} mb="24px">
          Referral Link
        </Heading>

        <Text style={{ fontSize: '1.5em' }}>Click on Button to copy your referral link</Text>
        <br />
        <CopyToClipboard text={`${window.location.href}?ref=${account}`} onCopy={() => console.log('Copied')}>
          <Button fullWidth>{`${window.location.href}?ref=${account}`.slice(0, 50)}</Button>
        </CopyToClipboard>
      </CardBody>
    </StyledTotalValueLockedCard>
  )
}

export default RefferalLink
