import React from 'react'
import { Card, CardBody, Heading, Text, Button } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'

const StyledInvitingCard = styled(Card)`
  margin: 1.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 32px;
  background-color: #0c1f3c;
  ${({ theme }) => theme.mediaQueries.sm} {
    // margin: 0 1rem 0 1rem;
  }
`

const RefferalCard3 = () => {
  return (
    <StyledInvitingCard style={{ background: '#0c1f3c', textAlign: 'center' }}>
      <CardBody style={{ background: '#0c1f3c' }}>
        <img src="/images/airdrop.png" alt="jf" style={{ margin: 'auto', width: '150px', display: 'block' }} />
        {/* <Text style={{marginTop:"20px"}}>Earn ECG</Text> */}
        <Heading style={{ margin: '1rem 0 1rem 0', color: '#fff' }}>Earn ECG</Heading>
        <Text style={{ marginTop: '10px', color: '#c0c0c0' }}>Recieve referral rewards in ECG </Text>
        <Text style={{ marginTop: '10px', color: '#c0c0c0' }}>token from your friends earnings</Text>
        {/* <Button fullWidth>Start Inviting</Button> */}
      </CardBody>
    </StyledInvitingCard>
  )
}

export default RefferalCard3
